import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CallDataContext } from '../..';
import { ICallDataContext } from '../../../../types/Call';

interface CallerNotHereProps {
    isChat: boolean;
}

export function CallerNotHere({ isChat }: CallerNotHereProps) {
    const { t } = useTranslation();
    const callData = useContext<ICallDataContext>(CallDataContext);

    const getDoctorWaitingHeader = () => {
        if (isChat) {
            return t('video.doctor.WaitingHeaderChat');
        }
        return t('video.doctor.WaitingHeaderVideo');
    };

    const getPatientWaitingHeader = () => {
        if (isChat) {
            return t('video.patient.WaitingHeaderChat');
        }
        return t('video.patient.WaitingHeaderVideo');
    };

    return (
        <div className="c-info -callerAbsent -bgWhite d-flex_col align-items-center justify-content-center">
            <div className="c-loader -mb40"></div>
            <span className="t-text -f20_30 -wBold -cBlack6 -lSpacing-3 -mb12 -tCenter">
                {callData?.isDoctor ? getDoctorWaitingHeader() : getPatientWaitingHeader()}
            </span>
            <p className="c-info_text t-text -f14_26 -cGrey4 -lSpacing2h -mb16 -tCenter">
                {callData && callData.isDoctor
                    ? t('video.doctor.WaitingParagraph')
                    : t('video.patient.WaitingParagraph')}
            </p>
            <span className="t-text -f14_26 -cBlack5 -lSpacing2h -tCenter -wBold">
                {callData && callData.isDoctor
                    ? t('video.doctor.WaitingEnd')
                    : t('video.patient.WaitingEnd')}
            </span>
        </div>
    );
}
