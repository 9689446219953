import React, { ReactElement, RefObject, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Attachment from '../Attachment/Attachment';

import { CallDataContext } from '../..';
import { ICallDataContext } from '../../../../types/Call';

interface ICustomChatInputProps {
    submitMessage: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    setTextArea: React.Dispatch<React.SetStateAction<string>>;
    textArea: string;
    textAreaRef: RefObject<HTMLDivElement>;
    fileName: string;
    status: '' | 'loading' | 'loaded';
    cancelFunction: () => void;
    blob: Blob | null;
    membersList: string[];
    textAreaValue: number;
    uploadMediaFile: (controller: AbortController) => void;
}

const CustomChatInput = ({
    submitMessage,
    setTextArea,
    textArea,
    textAreaRef,
    blob,
    status,
    cancelFunction,
    fileName,
    membersList,
    textAreaValue,
    uploadMediaFile,
}: ICustomChatInputProps): ReactElement => {
    const { t } = useTranslation();
    const callData = useContext<ICallDataContext>(CallDataContext);
    const isDoctor = callData.isDoctor;

    const translatePlaceholder = () => {
        return t('chat.general.ChatPlaceholder') === 'Napisz wiadomość...'
            ? ''
            : '-eng';
    };

    const setCaretAtEnd = () => {
        const body: any = document.body;
        if (textAreaRef.current) {
            textAreaRef.current.focus();
            if (
                typeof window.getSelection != 'undefined' &&
                typeof document.createRange != 'undefined'
            ) {
                const range = document.createRange();
                range.selectNodeContents(textAreaRef.current);
                range.collapse(false);
                const sel = window.getSelection();
                sel && sel.removeAllRanges();
                sel && sel.addRange(range);
            } else if (typeof body.createTextRange != 'undefined') {
                const textRange = body.createTextRange();
                textRange.moveToElementText(textAreaRef.current);
                textRange.collapse(false);
                textRange.select();
            }
        }
    };

    return (
        <div
            className={`form-control c-visitPage_chatArea_textarea t-text -f16 -wMBold -lSpacing2 -cBlack5 -customInput ${
                (textAreaValue && textAreaValue > 0) || status === 'loaded'
                    ? '-active'
                    : ''
            } ${membersList?.length > 1 ? '' : '-disabled'}`}
            onClick={() => {
                textAreaRef.current?.focus();
            }}
        >
            <div className='attachment'>
                {blob && status !== '' && (
                    <div className='c-fileUploadInfo -mb4'>
                        <Attachment
                            fileName={fileName}
                            status={status}
                            cancelFunction={() => cancelFunction()}
                            uploadMediaFile={uploadMediaFile}
                        />
                    </div>
                )}
            </div>
            <div
                className={`c-CustomInput ${translatePlaceholder()} ${
                    membersList?.length > 1 ? '' : '-disabled'
                } ${isDoctor ? '-doctorChat' : '-patientChat'}`}
                ref={textAreaRef}
                contentEditable={membersList?.length > 1}
                onKeyPress={(e) => {
                    submitMessage(e);
                }}
                onInput={() => {
                    textAreaRef.current &&
                        setTextArea(textAreaRef.current?.innerText);

                    if (
                        textAreaRef.current?.textContent &&
                        textAreaRef.current?.textContent?.length > 200
                    ) {
                        textAreaRef.current.textContent =
                            textAreaRef.current?.textContent?.substring(0, 200);
                        setCaretAtEnd();
                    }
                }}
                onPaste={(e: any) => {
                    const bufferText = (
                        e.originalEvent || e
                    ).clipboardData.getData('Text');
                    e.preventDefault();
                    document.execCommand('insertText', false, bufferText);
                }}
            ></div>
        </div>
    );
};

export default CustomChatInput;
