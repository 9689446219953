import React from 'react';
import { Route, Routes } from 'react-router-dom';

import VisitPage from '../../pages/VisitPage';
import { NotFoundPage } from '../../pages/NotFoundPage/NotFoundPage';
import EndConversationPage from '../../pages/EndConversationPage/EndConversationPage';
import { useEffect } from 'react';
import AgoraRTC from 'agora-rtc-react';
import { VisitFinishedPage } from '../../pages/VisitFinishedPage/VisitFinishedPage';
import { VisitNotReady } from '../../pages/VisitNotReadyPage/VisitNotReadyPage';
import { UnexpectedErrorPage } from '../../pages/UnexpectedErrorPage/UnexpectedErrorPage';

export default function Routing() {
    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'DEV' || process.env.REACT_APP_ENV === 'TEST') {
            AgoraRTC.setLogLevel(1);
        } else {
            AgoraRTC.setLogLevel(4);
        }
    });
    return (
        <Routes>
            <Route path="/" element={<VisitPage />} />
            <Route path="/endedConversation" element={<EndConversationPage doctorEnded />} />
            <Route path="/endConversation" element={<EndConversationPage />} />
            <Route path="/not-found" element={<NotFoundPage />} />
            <Route path="/visit-finished" element={<VisitFinishedPage />} />
            <Route path="/visit-not-ready" element={<VisitNotReady />} />
            <Route path="/unexpected-error" element={<UnexpectedErrorPage />} />
        </Routes>
    );
}
