import React, { RefObject, useCallback, useState, useRef } from 'react';

import useAgoraRtm from '../../../../hooks/useAgoraRtm';
import { RtmFileMessage } from '../../../../types/AgoraRtm';

import FileSaver from 'file-saver';

const useChat = (
    name: string,
    channelName: string,
    userId: string,
    isDoctor: boolean,
    isChatOnlyMode: boolean
) => {
    const [textArea, setTextArea] = useState('');
    const [fileMessage, setFileMessage] = useState<RtmFileMessage | null>(null);
    const [isFileOversize, setIsFileOversize] = useState(false);
    const [fileUploadState, setFileUploadState] = useState<{
        fileName: string;
        status: 'loading' | 'loaded' | '';
        blob: Blob | null;
    }>({ fileName: '', status: '', blob: null });

    const mediaFileInput: HTMLInputElement | null = document.querySelector(
        '.c-visitPage_chatArea_fileInput'
    );
    const textAreaRef = useRef<HTMLDivElement>(null);

    const { messages, sendChannelMessage, client, channel, sendChannelFileMessage, setMessages } =
        useAgoraRtm(name, channelName, userId, isDoctor, isChatOnlyMode);
    const submitMessage = useCallback(
        (e?: React.KeyboardEvent<HTMLDivElement>) => {
            if (e && e.charCode !== 13) return;
            e && e.preventDefault();

            if (textAreaRef.current?.innerText.trim().length === 0 && fileMessage === null) return;
            if (textAreaRef.current && textAreaRef.current?.innerText.trim().length !== 0) {
                sendChannelMessage(textAreaRef.current?.innerText.trim());
                textAreaRef.current.innerText = '';
                setTextArea('');
            }
            if (fileMessage !== null) {
                sendChannelFileMessage(fileMessage);
                mediaFileInput && (mediaFileInput.value = '');
                setFileMessage(null);
                setFileUploadState({
                    fileName: '',
                    status: '',
                    blob: null,
                });
                setTextArea('');
            }
        },
        [sendChannelMessage, fileMessage, mediaFileInput, sendChannelFileMessage]
    );

    const createBloB = async (file: File | null | undefined) =>
        file &&
        new Blob([new Uint8Array(await file.arrayBuffer())], {
            type: file.type,
        });

    const downloadFile = async (id: string | undefined, name: string | undefined) => {
        // Receive a channel file message:
        const blob = id && (await client.downloadMedia(id));

        blob && (await FileSaver.saveAs(blob, name));
    };

    const cancelUploading = () => {
        setFileMessage(null);
        setFileUploadState({ fileName: '', status: '', blob: null });
        mediaFileInput && (mediaFileInput.value = '');
    };

    const createInputMediaFile = async () => {
        setFileUploadState({
            fileName: '',
            status: '',
            blob: null,
        });

        const chosenFile = mediaFileInput?.files && mediaFileInput?.files[0];
        const fileName = chosenFile?.name;
        const blob = await createBloB(chosenFile);

        if (blob && blob?.size >= 30000000) return setIsFileOversize(true);
        fileName &&
            blob &&
            setFileUploadState({
                fileName: fileName,
                status: 'loading',
                blob: blob,
            });
    };

    const uploadMediaFile = async (controller: AbortController) => {
        try {
            const blob = fileUploadState.blob;
            const fileName = fileUploadState.fileName;
            // Upload a Blob to the Agora server
            const mediaMessage =
                blob &&
                (await client.createMediaMessageByUploading(
                    blob,
                    {
                        messageType: 'FILE',
                        fileName: fileName,
                        description: 'send file',
                    },
                    {
                        cancelSignal: controller.signal,
                        onOperationProgress: ({ currentSize, totalSize }) => {
                            if (
                                process.env.REACT_APP_ENV === 'DEV' ||
                                process.env.REACT_APP_ENV === 'TEST'
                            )
                                console.log(currentSize, totalSize);
                        },
                    }
                ));

            // Create an RtmFileMessage instance
            const fileMessageInstance = client.createMessage({
                mediaId: mediaMessage?.mediaId, // Your mediaId
                messageType: 'FILE',
                fileName: mediaMessage?.fileName,
                description: 'send file',
                size: mediaMessage?.size,
                thumbnail: mediaMessage?.thumbnail,
            });

            setFileMessage(fileMessageInstance);
            fileName &&
                blob &&
                setFileUploadState({
                    fileName: fileName,
                    status: 'loaded',
                    blob: blob,
                });
        } catch {
            console.error('upload canceled');
        }
    };

    const leaveChannel = async () => {
        await channel.leave();
    };

    const resizeTextArea = (textAreaRef: RefObject<HTMLTextAreaElement>) => {
        if (textAreaRef.current) {
            const value = textAreaRef.current.value;
            const scrollHeight = textAreaRef.current.scrollHeight;
            if (value.length < 18) return (textAreaRef.current.style.height = '56px');

            textAreaRef.current.style.height =
                scrollHeight > 120 ? 120 + 'px' : scrollHeight + 'px';
        }
    };

    const scrollToBottom = (chatMessagesBoxRef: RefObject<HTMLDivElement>) => {
        if (chatMessagesBoxRef.current) {
            chatMessagesBoxRef.current.scrollTop = chatMessagesBoxRef.current?.scrollHeight + 500;
        }
    };

    return {
        leaveChannel,
        submitMessage,
        // submitMessageByButton,
        messages,
        textArea,
        fileMessage,
        setTextArea,
        downloadFile,
        uploadMediaFile,
        scrollToBottom,
        resizeTextArea,
        fileUploadState,
        cancelUploading,
        isFileOversize,
        setIsFileOversize,
        setMessages,
        sendChannelMessage,
        textAreaRef,
        createInputMediaFile,
    };
};

export default useChat;
