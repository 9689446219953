import React, { ReactElement } from 'react';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';

import LogoDesktop from '../../assets/svg/logoDesktop.svg';
import LogoMobile from '../../assets/svg/logoMobile.svg';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const EndedConversationPage = ({
    doctorEnded,
}: {
    doctorEnded?: boolean;
}): ReactElement => {
    const { width } = useWindowDimensions();
    const { t } = useTranslation();
    const location = useLocation();
    const navigateTo = useNavigate();
    useEffect(() => {
        if (location.state) {
            navigateTo('.', { state: null });
            window.location.reload();
        }
    }, []);
    return (
        <div className='c-endConversationPage'>
            <div className='c-endConversationPage_wrapper -bgWhite d-flex_col'>
                <div className='c-endConversationPage_wrapper_logo d-flex_col -mb72 -mb48md'>
                    <img
                        className='-mb16'
                        src={width > 992 ? LogoDesktop : LogoMobile}
                        alt='logo enel-med'
                    />
                    <p className='t-text c-endConversationPage_wrapper_logo_hashtag'>
                        #zdrowiej
                    </p>
                </div>

                <p className='c-endConversationPage_wrapper_header -mb12 -tCenter'>
                    {doctorEnded
                        ? t('endConversation.Title2')
                        : t('endConversation.Title')}
                </p>

                <p className='c-endConversationPage_wrapper_subtitle -f14 -tCenter -mb16'>
                    {doctorEnded
                        ? t('endConversation.Subtitle2')
                        : t('endConversation.Subtitle')}
                </p>
                {!doctorEnded && (
                    <p className='c-endConversationPage_wrapper_thanks -f14'>
                        {t('endConversation.ThanksText')}
                    </p>
                )}
            </div>
        </div>
    );
};

export default EndedConversationPage;
