/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import camOff from '../../../../assets/svg/camOff.svg';
import camOn from '../../../../assets/svg/camOn.svg';
import chat from '../../../../assets/svg/chat.svg';
import chat2 from '../../../../assets/svg/chat-2.svg';
import chatNew2 from '../../../../assets/svg/chatNew-2.svg';
import endCall from '../../../../assets/svg/endCall.svg';
import maximize from '../../../../assets/svg/maximize.svg';
import micOff from '../../../../assets/svg/micOff.svg';
import micOn from '../../../../assets/svg/micOn.svg';
import replace from '../../../../assets/svg/replaceCam.svg';
import send from '../../../../assets/svg/send.svg';
import closeChatIcon from '../../../../assets/svg/close-chat.svg';
import greenCamIcon from '../../../../assets/svg/greenCam.svg';
import greenPhoneIcon from '../../../../assets/svg/greenPhone.svg';
import arrowBackIcon from '../../../../assets/svg/arrowBack.svg';

export function TwoStateBtn({
    type,
    func,
    state,
    isDisabled = false,
}: {
    type: string;
    func: () => void;
    state: boolean;
    isDisabled?: boolean;
}) {
    const [iconPair, setIconPair] = useState<any[]>([]);

    useEffect(() => {
        switch (type) {
            case 'video':
                setIconPair([camOn, camOff]);
                break;
            case 'audio':
                setIconPair([micOn, micOff]);
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <button
            title={isDisabled ? 'Twoje urządzenie nie posiada kamery.' : ''}
            disabled={isDisabled}
            className={`c-controlBtn ${!state ? '-active' : ''}`}
            onClick={() => {
                func();
            }}
        >
            {type === 'maximize' ? (
                <img
                    src={maximize}
                    alt={`${type} icon`}
                    className='c-controlBtn_icon -cWhite'
                />
            ) : (
                <img
                    src={state ? iconPair[0] : iconPair[1]}
                    alt={`${type} icon`}
                    className='c-controlBtn_icon -cWhite'
                />
            )}
        </button>
    );
}

export function OneStateBtn({
    disabled = false,
    sizeLg = false,
    type,
    func,
    red = true,
    className = '',
}: {
    disabled?: boolean;
    sizeLg?: boolean;
    type: string;
    func: () => void;
    red?: boolean;
    className?: string;
}) {
    return (
        <button
            disabled={disabled}
            className={`c-controlBtn ${sizeLg ? '-lg' : ''} ${
                type === 'send' ? '-bgGreen' : ''
            } ${red ? '-active' : ''} ${className} ${
                type === 'maximize' ? '-maximize' : ''
            } ${type === 'end' ? '-endCall' : ''}`}
            onClick={func}
        >
            <img
                src={
                    type === 'send'
                        ? send
                        : type === 'end'
                        ? endCall
                        : type === 'maximize'
                        ? maximize
                        : replace
                }
                alt={type === 'send' ? 'send message icon' : 'end call icon'}
            />
        </button>
    );
}

export function ChatBtn({
    type,
    func,
    className,
    mobile,
    newMsg,
    setNewMsg,
}: {
    type: string;
    func: () => void;
    className: string;
    mobile: boolean;
    newMsg: boolean;
    setNewMsg: (arg: boolean) => void;
}) {
    return (
        <button
            className={`c-controlBtn -chat ${className} `}
            onClick={() => {
                func();
                newMsg && setNewMsg(!newMsg);
            }}
        >
            {mobile ? (
                <img
                    src={newMsg ? chatNew2 : chat2}
                    alt={`${type} icon`}
                    className='c-controlBtn_icon -cWhite'
                />
            ) : (
                <img
                    src={newMsg ? chatNew2 : chat}
                    alt={`${type} icon`}
                    className='c-controlBtn_icon -cWhite'
                />
            )}
        </button>
    );
}

export function RoundButton({
    func,
    type,
    extraClassName,
    isDisabled,
}: {
    func: () => void;
    type: string;
    extraClassName?: string;
    isDisabled?: boolean;
}) {
    return (
        <button
            className={`c-roundBtn ${extraClassName ? extraClassName : ''}`}
            onClick={() => {
                func();
            }}
            disabled={isDisabled}
        >
            <img
                src={
                    type === 'close'
                        ? closeChatIcon
                        : type === 'phone'
                        ? greenPhoneIcon
                        : type === 'camera'
                        ? greenCamIcon
                        : type === 'darkBack'
                        ? arrowBackIcon
                        : ''
                }
                alt={`${type} icon`}
            />
        </button>
    );
}
