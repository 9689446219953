import AgoraRTC, {
    AgoraVideoPlayer,
    IAgoraRTCRemoteUser,
    ICameraVideoTrack,
} from 'agora-rtc-react';

import { CallerCameraOff } from '../CallerCameraOff';
import { CallerNotHere } from '../CallerNotHere';
import { OwnCameraOff } from '../OwnCameraOff';
import { OneStateBtn } from '../Buttons';

import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { CallDataContext } from '../..';
import { useContext, useEffect, useState } from 'react';

const Videos = ({
    users,
    cameraOn,
    camTrack,
    userId,
    fullSize,
    micOnly,
    start,
}: {
    users: IAgoraRTCRemoteUser[];
    camTrack: ICameraVideoTrack | null;
    cameraOn: boolean;
    userId: string;
    fullSize: boolean;
    micOnly: boolean;
    start: boolean;
}) => {
    const [onlyOneCamera, setOnlOneCamera] = useState(true);
    const [firstRender, setFirstRender] = useState(true);

    const { width } = useWindowDimensions();
    const { membersList, memberLeft } = useContext(CallDataContext);

    const checkCameras = async () => {
        const cams = await AgoraRTC.getCameras();

        setOnlOneCamera(cams.length === 1);
    };

    const switchCameras = async () => {
        const cameras = await AgoraRTC.getCameras();
        const cameraContainer = document.querySelector<HTMLElement>('.vid.c-visitPage_video.-own');
        if (cameras.length > 1) {
            camTrack?.setDevice(
                cameras[0].label === camTrack?.getMediaStreamTrack().label
                    ? cameras[1].deviceId
                    : cameras[0].deviceId
            );
            camTrack?.stop();
            camTrack?.play(cameraContainer as HTMLElement, {
                mirror: cameras[0].label !== camTrack?.getMediaStreamTrack().label,
            });
        }
    };

    useEffect(() => {
        checkCameras();
    }, []);

    useEffect(() => {
        if (micOnly && camTrack && start) {
            camTrack.setEnabled(false);
        }
    }, [micOnly, start]);

    useEffect(() => {
        membersList.length > 1 && setFirstRender(false);
    }, [membersList]);

    return (
        <div
            id="videos"
            className={`c-visitPage_videos ${fullSize ? '-fullScreen' : ''} ${
                users[0] && users[0].videoTrack ? '-callerCamOn' : ''
            }`}
        >
            {cameraOn && camTrack && !micOnly ? (
                <div className="c-ownCameraOn">
                    <AgoraVideoPlayer
                        className="vid c-visitPage_video -own"
                        style={
                            width > 768 ? { width: 224, height: 130 } : { width: 122, height: 216 }
                        }
                        videoTrack={camTrack}
                        key={userId}
                    />
                    {!onlyOneCamera && (
                        <OneStateBtn
                            type="switch"
                            func={switchCameras}
                            red={false}
                            className="d-flex d-lg-none"
                        />
                    )}
                </div>
            ) : (
                <OwnCameraOff
                    camTrack={camTrack}
                    callerVideoOn={users.length !== 0 && users[0].videoTrack ? true : false}
                    fullSize={fullSize}
                />
            )}

            {membersList.length < 2 ? (
                memberLeft ? (
                    <CallerCameraOff memberLeft={true} />
                ) : (
                    <CallerNotHere isChat={false} />
                )
            ) : users.length > 0 ? (
                users.map((user) => {
                    if (user.videoTrack) {
                        return (
                            <AgoraVideoPlayer
                                className="vid c-visitPage_video -caller"
                                videoTrack={user.videoTrack}
                                key={user.uid}
                            />
                        );
                    } else if (user.videoTrack === undefined) return <CallerCameraOff />;
                })
            ) : (
                <CallerCameraOff />
            )}
        </div>
    );
};

export default Videos;
