import React from 'react';
import { t } from 'i18next';

import VisitNotFoundIcon from '../../assets/svg/visit-not-found.svg';
import { VisitInfo } from '../../components/VisitInfo/VisitInfo';
import { VisitInfoPageContainer } from '../../components/VisitInfoPageContainer/VisitInfoPageContainer';
import { useLocation } from 'react-router-dom';

export function UnexpectedErrorPage() {
    const location = useLocation();

    return (
        <VisitInfoPageContainer className="d-flex_col unexpected_error_page_container">
            <VisitInfo
                title={t('unexpectedError.Title')}
                icon={{
                    src: VisitNotFoundIcon,
                    alt: t('unexpectedError.ImageAlt'),
                }}
                description={t('unexpectedError.Description')}
            />
            <div className="unexpected_error_info container -mt32">
                <div className="unexpected_error_message">
                    Error: {location.state.error?.message}
                </div>
                <div className="unexpected_error_stack">
                    Stacktrace: {location.state.error?.stack}
                </div>
            </div>
        </VisitInfoPageContainer>
    );
}
