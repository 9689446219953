import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import time from '../../../../assets/svg/camOff-black.svg';

import { CallDataContext } from '../..';
import { ICallDataContext } from '../../../../types/Call';

interface ICallerCameraOffProps {
    memberLeft?: boolean;
}

export function CallerCameraOff({ memberLeft }: ICallerCameraOffProps) {
    const { t } = useTranslation();
    const callData = useContext<ICallDataContext>(CallDataContext);
    return (
        <div className='c-info -callerCameraOff  d-flex_col align-items-center -bgWhite justify-content-center'>
            <div className='-bgGrey5 c-info_iconWrapper d-flex align-items-center justify-content-center'>
                <img
                    src={time}
                    alt='turned off camera icon'
                    className='c-info_icon'
                />
            </div>

            {memberLeft ? (
                <>
                    <span className='t-text -f20_30 -wBold -lSpacing-3 -tCenter -mt32 -mb12'>
                        {callData && callData.isDoctor
                            ? t('video.doctor.MemberLeft')
                            : t('video.patient.MemberLeft')}
                    </span>
                    <p className='c-info_text t-text -f14_26 -cGrey4 -lSpacing2h -mb16 -tCenter'>
                        {t('video.general.MemberLeftSubtitle')}
                    </p>
                </>
            ) : (
                <span className='t-text -f20_30 -wBold -lSpacing-3 -tCenter -mt32'>
                    {callData && callData.isDoctor
                        ? callData.patientName
                        : callData?.doctor}
                    <br />
                    {t('video.general.CallerCameraDisabled')}
                </span>
            )}
        </div>
    );
}
