import React, {
    ReactElement,
    Dispatch,
    SetStateAction,
    useEffect,
} from 'react';

import closeMessageIcon from '../../../../assets/svg/whiteCloseIcon.svg';
import { useTranslation } from 'react-i18next';

interface IFileOversizeMessageProps {
    closeFunction: Dispatch<SetStateAction<boolean>>;
    videoMode: boolean;
}

const FileOversizeMessage = ({
    closeFunction,
    videoMode,
}: IFileOversizeMessageProps): ReactElement => {
    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(() => {
            closeFunction(false);
        }, 3500);
    }, []);

    return (
        <div
            className={`c-fileOversizeMessage t-text -f14 d-flex ${
                !videoMode ? '-videoMode' : ''
            }`}
        >
            <img
                className='c-fileOversizeMessage_icon'
                src={closeMessageIcon}
                alt='closeIcon'
                onClick={() => closeFunction(false)}
            />
            {t('chat.general.FileOversize')}
        </div>
    );
};

export default FileOversizeMessage;
