import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Message } from '../types/Message';
import { RtmMessage, RtmFileMessage } from '../types/AgoraRtm';
import AgoraRTM from 'agora-rtm-sdk';
import { useTranslation } from 'react-i18next';
import { RtmStatusCode } from 'agora-rtm-sdk';

import { CallDataContext } from '../pages/VisitPage';
import { useAudio } from './useAudio';
import doctorJoinSound from '../assets/doctor-join-sound.mp3';

const client = AgoraRTM.createInstance(`${process.env.REACT_APP_APPID}`, {
    logFilter:
        process.env.REACT_APP_ENV === 'DEV' || process.env.REACT_APP_ENV === 'TEST'
            ? AgoraRTM.LOG_FILTER_INFO
            : AgoraRTM.LOG_FILTER_OFF,
});

const useAgoraRtm = (
    name: string,
    channelName: string,
    userId: string,
    isDoctor: boolean,
    isChatOnlyMode: boolean
) => {
    const playSound = useAudio(doctorJoinSound);
    const [messages, setMessages] = useState<Message[]>([]);
    const [channelMembers, setChannelMembers] = useState<string[]>([]);
    const [currentMessage, setCurrentMessage] = useState<Message>();
    const [memberLeft, setMemberLeft] = useState(false);
    const [connectionStates, setConnectionStates] = useState<
        '' | 'DISCONNECTED' | 'CONNECTING' | 'CONNECTED' | 'RECONNECTING' | 'ABORTED'
    >('');

    enum AreaCode {
        GLOBAL = 'GLOBAL',
        INDIA = 'INDIA',
        JAPAN = 'JAPAN',
        ASIA = 'ASIA',
        EUROPE = 'EUROPE',
        CHINA = 'CHINA',
        NORTH_AMERICA = 'NORTH_AMERICA',
    }

    AgoraRTM.setArea({
        areaCodes: [AreaCode.EUROPE],
    });

    const { setCallDataContext, token } = useContext(CallDataContext);
    const { t } = useTranslation();
    const channel = useMemo(() => client.createChannel(channelName), [channelName]);
    const color = useRef('#5CD8FD').current;

    const initRtm = async () => {
        await client.login({
            uid: userId,
            token: token,
        });
        await channel.join();
        await client.setLocalUserAttributes({
            name: userId,
            userName: name,
            color,
            isDoctor: String(isDoctor),
        });
        const members = await channel.getMembers();
        if (members.length > 1) {
            for (const memberId of members) {
                await handleSystemMessages(t('chat.general.SystemConnected'), memberId);
            }
        } else {
            await handleSystemMessages(t('chat.general.SystemConnected'), userId);
        }
        await updateMemberList();

        // when patient or doctor join, when someone of them is already waiting - send doctor's message
        if (members.length > 1) {
            let doctorName = '';
            if (isDoctor) {
                doctorName = name;
            } else {
                // when doctor waits and patient joins
                const doctorMemberIdx = 1;
                const doctorMemberId = members[doctorMemberIdx];
                const { userName } = await client.getUserAttributes(doctorMemberId);
                doctorName = userName;
            }
            const doctorWelcomeMessage = getDoctorWelcomeMessage(doctorName);
            await sendChannelMessage(doctorWelcomeMessage);
        }
    };

    const getCurrentTime = () => {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
    };

    const handleMessageReceived = async (data: RtmMessage, uid: string) => {
        const user = await client.getUserAttributes(uid);
        if (data.messageType === 'TEXT') {
            const newMessageData = {
                user,
                message: data.text,
                time: getCurrentTime(),
            };
            setCurrentMessage(newMessageData);
            updateMemberList();
        } else if (data.messageType === 'FILE') {
            const newMsgData = {
                user,
                message: data.fileName,
                fileId: data.mediaId,
                time: getCurrentTime(),
            };
            setCurrentMessage(newMsgData);
            updateMemberList();
        }
    };
    const handleSystemMessages = async (text: string, uid: string) => {
        const user = await client.getUserAttributes(uid);

        const newMessageData = {
            user: { userName: 'System', name: '', color: '#fff' },
            message: text + `${user.userName} `,
            time: getCurrentTime(),
        };
        setCurrentMessage(newMessageData);
    };

    const handleSystemMessagesUserDisconnected = async (text: string, uid: string) => {
        const user = await client.getUserAttributes(uid);
        const newMessageData = {
            user: { userName: 'System', name: '', color: '#fff' },
            message: `${user.userName} ${text}`,
            time: getCurrentTime(),
        };
        setCurrentMessage(newMessageData);
    };

    const sendChannelMessage = async (text: string) => {
        await channel
            .sendMessage({ text })
            .then(() => {
                setCurrentMessage({
                    user: { userName: '(Me)', color },
                    message: text,
                    time: getCurrentTime(),
                });
            })
            .catch((error) => {
                if (process.env.REACT_APP_ENV === 'DEV' || process.env.REACT_APP_ENV === 'TEST')
                    console.log(error);
                const newMessageData = {
                    user: {
                        userName: '(Me)',
                        name: '',
                        color,
                        isError: 'error',
                    },
                    message: `${text}`,
                    time: getCurrentTime(),
                };
                setCurrentMessage(newMessageData);
            });
    };

    const sendChannelFileMessage = async (data: RtmFileMessage) => {
        channel.sendMessage(data).then(() => {
            setCurrentMessage({
                user: { userName: '(Me)', color },
                message: data.fileName,
                fileId: data.mediaId,
                time: getCurrentTime(),
            });
        });
    };

    const updateMemberList = async () => {
        const currentMembersList = await channel.getMembers();
        setChannelMembers(currentMembersList);
    };

    const logOutRtm = async () => {
        await channel.leave();
        await client.logout();
    };

    const getDoctorWelcomeMessage = (doctorName: string) => {
        const doctorWelcomeMessage = `${t('chat.doctor.HelloMessage')} ${doctorName}. ${t(
            'chat.doctor.HowCanIHelpMessage'
        )}`;
        return doctorWelcomeMessage;
    };

    useEffect(() => {
        if (currentMessage) {
            setMessages((messages) => [...messages, currentMessage]);
        }
    }, [currentMessage]);

    useEffect(() => {
        initRtm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        channel.on('ChannelMessage', (data, uid) => {
            handleMessageReceived(data, uid);
        });
        channel.on('MemberJoined', (memberId: string) => {
            handleSystemMessages(t('chat.general.SystemConnected'), memberId);

            // play sound to inform patient that doctor has joined
            if (!isDoctor && !isChatOnlyMode) {
                playSound();
            }

            updateMemberList();
            setMemberLeft(false);
        });
        channel.on('MemberLeft', (memberId) => {
            handleSystemMessagesUserDisconnected(t('chat.general.SystemDisconnected'), memberId);
            updateMemberList();
            setMemberLeft(true);
        });

        client.on('ConnectionStateChanged', (newState, reason) => {
            setConnectionStates((prevState) => {
                if (prevState === 'RECONNECTING') {
                    window.location.reload();
                }
                return newState;
            });
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCallDataContext((prevData) => {
            return {
                ...prevData,
                membersList: channelMembers,
                logOutRtm,
                connectionInfo: connectionStates,
                memberLeft: memberLeft,
            };
        });
    }, [channelMembers, connectionStates]);

    return {
        sendChannelMessage,
        messages,
        client,
        channel,
        sendChannelFileMessage,
        setMessages,
    };
};
export default useAgoraRtm;
