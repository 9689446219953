import React from 'react';
import { t } from 'i18next';

import VisitFinishedIcon from '../../assets/svg/visit-finished.svg';
import { VisitInfo } from '../../components/VisitInfo/VisitInfo';
import { VisitInfoPageContainer } from '../../components/VisitInfoPageContainer/VisitInfoPageContainer';

export function VisitFinishedPage() {
    return (
        <VisitInfoPageContainer>
            <VisitInfo
                title={t('visitFinished.Title')}
                icon={{
                    src: VisitFinishedIcon,
                    alt: t('visitFinished.ImageAlt'),
                }}
                description={t('visitFinished.Description')}
                thankYouText={t('visitFinished.ThankYouText')}
            />
        </VisitInfoPageContainer>
    );
}
