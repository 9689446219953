import {useEffect, useState, useRef, createContext} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Chat from './partials/Chat';
import VideoContent from './partials/VideoContent/VideoContent';
import i18next from 'i18next';

import {ICallDataContext} from '../../types/Call';
import {EventSourcePolyfill} from 'event-source-polyfill';
import {encodeToken} from '../../tools/encodeToken';

interface VisitDetails {
    date: string;
    doctor: string;
    formofcontactId: number;
    isBlocked: boolean;
    internalStatus: 'too-early' | 'too-late';
    message: string;
    patient: string;
    service: string;
    status: boolean;
}

const initialValues = {
    video: false,
    visitId: '',
    doctor: 'doctor',
    patientName: 'patient',
    scheduledDate: '',
    service: '',
    isDoctor: false,
    membersList: [],
    setCallDataContext: () => false,
    dataLoaded: false,
    logOutRtm: () => true,
    connectionInfo: '',
    formofcontactId: 3,
    token: '',
    tokenLoaded: false,
    baseTokenString: 'Bi11enn1Um',
};

const getRandomNumberInRangeToString = (min: number, max: number) => {
    const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
    return randomNumber.toString();
}

export const CallDataContext = createContext<ICallDataContext>(initialValues);

export default function VisitPage() {
    const [openChat, setOpenChat] = useState(false);
    const [cameraOn, setCameraOn] = useState(true);
    const [callDataContext, setCallDataContext] = useState<ICallDataContext>(initialValues);
    const [isNewMessageReceived, setIsNewMessageReceived] = useState(false);
    const [isChatOnlyMode, setIsChatOnlyMode] = useState(true);
    const [isUnstableConnection, setIsUnstableConnection] = useState(false);
    const [micOnly, setMicOnly] = useState(false);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const {width} = useWindowDimensions();
    const channelName = searchParams.get('channelName');
    const userId = searchParams.get('userId');
    const type = searchParams.get('type');
    const encodedToken = useRef<string | null>(null);

    const fetchFbToken = async () => {
        const token = await encodeToken(initialValues.baseTokenString, getRandomNumberInRangeToString(450, 900));
        encodedToken.current = token;
    };
    useEffect(() => {
        fetchFbToken().then(()=>fetchCallData());
    }, []);
    
    useEffect(() => {
        if (!type || !channelName || !userId) {
            return navigate('/not-found');
        }
        if (type === '57481' || type === '53073') {
            setCallDataContext((prevData) => {
                return {
                    ...prevData,
                    isDoctor: type === '57481',
                    setCallDataContext,
                    visitId: channelName,
                };
            });
        } else return navigate('/not-found');
    }, [channelName, userId, type]);

    useEffect(() => {
        switch (callDataContext.formofcontactId) {
            case 0:
                navigate('/not-found');
                break;
            case 1:
                setIsChatOnlyMode(false);
                setMicOnly(true);
                break;
            case 2:
                setIsChatOnlyMode(false);
                setMicOnly(false);
                break;
        }
    }, [callDataContext.formofcontactId]);

    useEffect(() => {
        if (openChat) {
            document.documentElement.classList.add('-blocked');
            return;
        }
        document.documentElement.classList.remove('-blocked');
    }, [openChat]);

    useEffect(() => {
        if (callDataContext.dataLoaded && userId) {
            fetchToken(userId);
        }
    }, [callDataContext.dataLoaded]);

    useEffect(() => {
        navigator.connection?.addEventListener('change', () => {
            checkNetworkInfo();
        });

        return navigator.connection?.removeEventListener('change', () => {
            checkNetworkInfo();
        });
    }, []);

    useEffect(() => {
        if (!channelName) {
            return;
        }
        const eventSource = new EventSourcePolyfill(
            `${process.env.REACT_APP_MERCURE_URL}${channelName}`,
            {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_MERCURE_KEY}`,
                },
            }
        );
        eventSource.onmessage = (event) => {
            navigate('/endedConversation', {state: true});
        };
        eventSource.onerror = (err) => {
            // error log here
            console.log(err);
        };
        eventSource.onopen = (event) => console.log(event);
        return () => {
            eventSource.close();
        };
    }, [channelName]);

    useEffect(() => {
        appHeight();
        window.addEventListener('resize', appHeight);

        return window.addEventListener('resize', appHeight);
    }, []);

    const checkNetworkInfo = () => {
        if (navigator.connection) {
            const network = navigator.connection.effectiveType;
            if (network === '2g') {
                return setIsUnstableConnection(true);
            }
        }

        setIsUnstableConnection(false);
    };

    const appHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };

    const fetchCallData = async () => {
        const link = process.env.REACT_APP_CALL_DETAILS_URL;
        try {
            const response = await fetch(`${link}${channelName}&id=${userId}`, {
                headers: {
                    'FB-Token': encodedToken.current ?? '',
                    language: i18next.language,
                },
            });

            const data: VisitDetails = await response.json();

            if (!response.ok) {
                if (response.status === 404) {
                    navigate('/not-found');
                    return;
                }

                throw new Error(createUnexpectedErrorMessage(response, data));
            }

            if (!data.status) {
                if (data.internalStatus === 'too-early') {
                    return navigate('/visit-not-ready');
                } else if (data.internalStatus === 'too-late') {
                    return navigate('/visit-finished');
                }
            }

            const {doctor, formofcontactId, patient, service, isBlocked} = data;

            if (isBlocked) {
                return navigate('/endedConversation');
            }

            setCallDataContext((prevData) => {
                return {
                    ...prevData,
                    doctor,
                    service,
                    formofcontactId,
                    patientName: patient,
                    dataLoaded: true,
                };
            });
        } catch (err) {
            if (process.env.REACT_APP_ENV === 'DEV' || process.env.REACT_APP_ENV === 'TEST')
                console.log('fetch error:', err);

            return navigate('/unexpected-error', {
                state: {
                    error: err,
                },
            });
        }
    };

    const fetchToken = async (userId: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_RTM_TOKEN_URL}`, {
                method: 'POST',
                headers: {
                    'FB-Token': encodedToken.current ?? '',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId,
                }),
            });

            // or check for response.status
            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const data = await response.json();
            const {token} = data;

            setCallDataContext((prevData) => {
                return {
                    ...prevData,
                    token,
                    tokenLoaded: true,
                };
            });
        } catch (err) {
            if (process.env.REACT_APP_ENV === 'DEV' || process.env.REACT_APP_ENV === 'TEST') {
                console.log('fetch error:', err);
            }
            return navigate('/not-found');
        }
    };

    return (
        <CallDataContext.Provider value={callDataContext}>
            {callDataContext.tokenLoaded && (
                <div className="c-visitPage_container container -bgBlack5 -pt24 -pb24">
                    <div className="row c-wrapper" style={{}}>
                        <div className="col col-lg-9 fullSize">
                            {!isChatOnlyMode && channelName && userId && (
                                <VideoContent
                                    channelName={channelName}
                                    cameraOn={cameraOn}
                                    setCameraOn={setCameraOn}
                                    userId={userId}
                                    openChat={openChat}
                                    setOpenChat={setOpenChat}
                                    setIsNewMessageReceived={setIsNewMessageReceived}
                                    isNewMessageReceived={isNewMessageReceived}
                                    isUnstableConnection={isUnstableConnection}
                                    micOnly={micOnly}
                                    setMicOnly={setMicOnly}
                                />
                            )}
                        </div>
                        <div
                            className={`${width >= 992 ? 'col col-3 ' : 'c-visitPage_chatWrapper'
                                } ${width <= 992 && !openChat ? (isChatOnlyMode ? '' : 'd-none') : ''}`}
                            style={{maxHeight: '100%'}}
                        >
                            {channelName && userId && callDataContext.tokenLoaded && (
                                <Chat
                                    mobile={width <= 992}
                                    setOpenChat={setOpenChat}
                                    openChat={openChat}
                                    callData={callDataContext}
                                    setIsNewMessageReceived={setIsNewMessageReceived}
                                    chatName={channelName}
                                    userId={userId}
                                    isChatOnlyMode={isChatOnlyMode}
                                    setIsChatOnlyMode={setIsChatOnlyMode}
                                    isUnstableConnection={isUnstableConnection}
                                    setMicOnly={setMicOnly}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </CallDataContext.Provider>
    );
}
function createUnexpectedErrorMessage(response: Response, data: VisitDetails) {
    return `internalStatus: ${data.internalStatus}, message: ${data.message},
    status: ${response.status}, statusText: ${response.statusText
        } \nfull data object: ${JSON.stringify(data, null, 2)}`;
}
