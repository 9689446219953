import { ClientConfig } from 'agora-rtc-sdk-ng';
import {
    createClient,
    createCameraVideoTrack,
    createMicrophoneAudioTrack,
} from 'agora-rtc-react';


const APP_ID = `${process.env.REACT_APP_APPID}`;

const appId = APP_ID;

const useAgora = () => {
    const config: ClientConfig = {
        mode: 'rtc',
        codec: 'vp8',
    };
    const useClient = createClient(config);
    const useCameraTrack = createCameraVideoTrack();
    const useMicrophoneTrack = createMicrophoneAudioTrack();

    return {
        useClient,
        appId,
        useCameraTrack,
        useMicrophoneTrack,
    };
};

export default useAgora;
