import React, { ReactElement, useEffect } from 'react';

import AttachmentChatIcon from '../../../../assets/svg/attachmentChatIcon.svg';
import DownloadIcon from '../../../../assets/svg/download.svg';
import fileLoaderIcon from '../../../../assets/svg/fileDownloading.svg';
import RemoveFile from '../../../../assets/svg/removeFile.svg';

interface IAttachmentProps {
    fileName: string;
    clickFunction?: () => void;
    status: string;
    cancelFunction?: () => void;
    uploadMediaFile?: (controller: AbortController) => void;
}

const Attachment = ({
    fileName,
    clickFunction,
    status,
    cancelFunction,
    uploadMediaFile,
}: IAttachmentProps): ReactElement => {
    useEffect(() => {
        const abortController = new AbortController();

        uploadMediaFile && uploadMediaFile(abortController);

        return () => {
            abortController.abort();
        };
    }, []);

    return (
        <div className='c-attachment d-flex'>
            {clickFunction && status === '' && (
                <button
                    className='c-attachment_downloadBtn'
                    onClick={() => clickFunction()}
                >
                    <img src={DownloadIcon} alt='DownloadIcon' />
                </button>
            )}

            <div className='c-attachment_name t-text d-flex'>
                <img
                    className={`c-attachment_name_icon ${
                        status === 'loading' ? '-loading ' : ''
                    }`}
                    src={
                        status === 'loading'
                            ? fileLoaderIcon
                            : AttachmentChatIcon
                    }
                    alt='paperclip'
                />{' '}
                {fileName}{' '}
                {cancelFunction && status !== '' && (
                    <button
                        className='c-attachment_removeBtn'
                        onClick={() => cancelFunction()}
                    >
                        <img src={RemoveFile} alt='Remove Icon' />
                    </button>
                )}
            </div>
        </div>
    );
};

export default Attachment;
