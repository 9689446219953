import { useCallback, useEffect, useState } from 'react';
import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import useAgora from '../../hooks/useAgora';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { CallDataContext } from '.';
import { ICallDataContext } from '../../types/Call';

export const useVideoCall = (channelName: string, id: string) => {
    const navigateTo = useNavigate();
    const [inCall, setInCall] = useState(true);
    const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([]);
    const [start, setStart] = useState<boolean>(false);
    const [usersVideoDisabled, setUsersVideoDisabled] = useState<string[]>([]);

    const { useClient, appId, useMicrophoneTrack, useCameraTrack } = useAgora();
    const client = useClient();
    const { ready: camReady, track: camTrack } = useCameraTrack();
    const { ready: micReady, track: micTrack } = useMicrophoneTrack();
    const { logOutRtm }: ICallDataContext = useContext(CallDataContext);
    const init = useCallback(
        
        async (name: string) => {
            const res = await axios.get(
                `${process.env.REACT_APP_RTC_TOKEN_URL}?channelName=${channelName}`
            );
            client.on('user-published', async (user, mediaType) => {
                await client.subscribe(user, mediaType);

                if (mediaType === 'video') {
                    setUsers((prevUsers) => {
                        if (
                            prevUsers.some(
                                (savedUser) => savedUser.uid === user.uid
                            )
                        ) {
                            return [...prevUsers];
                        } else {
                            return [...prevUsers, user];
                        }
                    });
                    setUsersVideoDisabled((usersVideoDisabled) =>
                        usersVideoDisabled.filter(
                            (element) => element !== user.uid.toString()
                        )
                    );
                }
                if (mediaType === 'audio') {
                    user.audioTrack?.play();
                }
            });
            client.on('connection-state-change', (curState) => {
                if (curState === "DISCONNECTED") {
                    micTrack && micTrack.setEnabled(false);
                    camTrack && camTrack.setEnabled(false);
                    client.removeAllListeners();
                    setStart(false);
                    setInCall(false);
                    client.leave()
                    logOutRtm()
                    navigateTo('/endConversation');
                }
            })
            client.on('user-unpublished', (user, type) => {
                if (type === 'audio') {
                    user.audioTrack?.stop();
                }
                if (type === 'video') {
                    setUsersVideoDisabled((usersVideoDisabled) =>
                        usersVideoDisabled.includes(user.uid.toString())
                            ? [...usersVideoDisabled]
                            : [...usersVideoDisabled, user.uid.toString()]
                    );
                }
            });

            client.on('user-left', (user) => {
                setUsers((prevUsers) => {
                    return prevUsers.filter((User) => User.uid !== user.uid);
                });
            });

            await client.join(appId, name, res.data.tokenUuid, id);
            if (camTrack && micTrack)
                await client.publish([camTrack, micTrack]);
            if (!camTrack && micTrack) await client.publish(micTrack);
            setStart(true);
        },
        [appId, client, camTrack, micTrack, channelName]
    );

    useEffect(() => {
        // function to initialise the SDK
        if (micReady) {
            if ( process.env.REACT_APP_ENV === 'DEV' || process.env.REACT_APP_ENV === 'TEST') console.log('init ready on channel name ' + channelName);
            init(channelName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [micReady]);

    return {
        inCall,
        setInCall,
        appId,
        channelName,
        client,
        camTrack,
        micTrack,
        start,
        setStart,
        camReady,
        micReady,
        users,
        usersVideoDisabled,
    };
};
