import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';

import Videos from '../Videos/index';
import { Controls } from '../Controls/index';

import { useVideoCall } from '../../hooks';
import { useEffect } from 'react';

interface IVideoContentProps {
    channelName: string;
    cameraOn: boolean;
    userId: string;
    setOpenChat: (arg: boolean) => void;
    setCameraOn: (arg: boolean) => void;
    openChat: boolean;
    isNewMessageReceived: boolean;
    setIsNewMessageReceived: (arg: boolean) => void;
    isUnstableConnection: boolean;
    micOnly: boolean;
    setMicOnly: Dispatch<SetStateAction<boolean>>;
}

const VideoContent = ({
    channelName,
    cameraOn,
    userId,
    setCameraOn,
    setOpenChat,
    openChat,
    isNewMessageReceived,
    setIsNewMessageReceived,
    isUnstableConnection,
    micOnly,
    setMicOnly,
}: IVideoContentProps): ReactElement => {
    const [fullSize, setFullSize] = useState(false);

    const {
        camTrack,
        micTrack,
        users,
        camReady,
        micReady,
        setStart,
        setInCall,
        start,
        client,
    } = useVideoCall(channelName, userId);
    return (
        <>
            {micReady && (
                <Videos
                    {...{
                        users,
                        camTrack,
                        cameraOn,
                        userId,
                        fullSize,
                        micOnly,
                        start,
                    }}
                />
            )}
            {micReady && (
                <Controls
                    {...{
                        camReady,
                        camTrack,
                        micTrack,
                        setStart,
                        setInCall,
                        setFullSize,
                        fullSize,
                        setOpenChat,
                        setCameraOn,
                        cameraOn,
                        openChat,
                        isNewMessageReceived,
                        setIsNewMessageReceived,
                        isUnstableConnection,
                        micOnly,
                        users,
                        setMicOnly,
                        client,
                    }}
                />
            )}
        </>
    );
};

export default VideoContent;
