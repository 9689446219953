import React, { ReactChild } from 'react';

interface Props {
    children: ReactChild | ReactChild[];
    className?: string;
}

export function VisitInfoPageContainer({ children, className }: Props) {
    return (
        <div className={`visit_info_page_container ${className ? className : ''}`}>{children}</div>
    );
}
