import React, { ReactElement, useContext } from 'react';

import { RoundButton } from '../Buttons';

import logo from '../../../../assets/images/logo.png';

import { useTranslation } from 'react-i18next';
import { CallDataContext } from '../..';

interface IChatCallDetailsProps {
    mobile: boolean;
    setDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    chatName: string;
}

const ChatCallDetails = ({
    mobile,
    setDetailsOpen,
    chatName,
}: IChatCallDetailsProps): ReactElement => {
    const { t } = useTranslation();
    const { doctor, service } = useContext(CallDataContext);

    return (
        <div className='c-callDetails'>
            <div className='c-callDetails_header d-flex align-items-center'>
                {mobile && (
                    <RoundButton
                        extraClassName='-darkBgc'
                        type='darkBack'
                        func={() => setDetailsOpen(false)}
                    />
                )}
                <p className='t-text -f18'>{t('chat.general.DetailsHeader')}</p>
            </div>

            <div className='c-callDetails_content'>
                <p className='t-text -f14'>
                    <span className='c-callDetails_content_prefix'>
                        {t('chat.general.ChatID')}
                    </span>{' '}
                    {chatName}
                </p>
                <p className='t-text -f14'>
                    <span className='c-callDetails_content_prefix'>
                        {t('chat.general.Doctor')}
                    </span>{' '}
                    {doctor}
                </p>
                <p className='t-text -f14'>
                    <span className='c-callDetails_content_prefix'>
                        {t('chat.general.Service')}
                    </span>{' '}
                    {service}
                </p>

                <div className='c-callDetails_content_logo d-flex'>
                    <img src={logo} alt='logo enel-med' />
                    <p className='t-text -f16'>#zdrowiej</p>
                </div>
            </div>
        </div>
    );
};

export default ChatCallDetails;
