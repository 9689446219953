import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import XHR from "i18next-http-backend"
import LanguageDetector from 'i18next-browser-languagedetector';
import engLang from './assets/i18n/translations/en.json';
import plLang from './assets/i18n/translations/pl.json';

i18n.use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['querystring', 'navigator'],
            lookupQuerystring: 'lng',
        },
        resources: {
            pl: { translation: plLang },
            en: { translation: engLang },
        },
        fallbackLng: 'en',
        debug: false,
        interpolation: { escapeValue: false },
        supportedLngs: ['pl', 'en'],
    });

export default i18n;
