import { useState, useEffect, useContext } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { ControlsProps } from './types';
import { CallDataContext } from '../../index';

import LostConnectionInfo from '../ReconnectingInfo/LostConnectionInfo';
import { ChatBtn, OneStateBtn, TwoStateBtn } from '../Buttons';

export const Controls: React.FC<ControlsProps> = ({
    setStart,
    setInCall,
    setOpenChat,
    cameraOn,
    setCameraOn,
    isNewMessageReceived,
    setIsNewMessageReceived,
    camTrack,
    camReady,
    micTrack,
    isUnstableConnection,
    micOnly,
    setMicOnly,
    setFullSize,
    fullSize,
    client,
    users,
}) => {
    const [trackState, setTrackState] = useState({ video: true, audio: true });
    const [temporaryDisabled, setTemporaryDisabled] = useState(false);
    const [timeoutId, setTTimeoutId] = useState<ReturnType<typeof setTimeout>>(
        setTimeout(() => true, 0)
    );

    const navigateTo = useNavigate();
    const location = useLocation();
    const { logOutRtm, connectionInfo, isDoctor } = useContext(CallDataContext);

    const setVideoToFullScreen = () => {
        const el: any = document.querySelector('.fullSize');
        setFullSize(true);
        if (el.requestFullscreen) {
            el.requestFullscreen();
        } else if (el.mozRequestFullScreen) {
            el.mozRequestFullScreen();
        } else if (el.webkitRequestFullscreen) {
            el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) {
            el.msRequestFullscreen();
        }
    };

    const setVideoToNormalScreen = () => {
        const doc: any = document;
        setFullSize(false);
        if (doc.exitFullscreen) {
            doc.exitFullscreen();
        } else if (doc.mozCancelFullScreen) {
            doc.mozCancelFullScreen();
        } else if (doc.webkitExitFullscreen) {
            doc.webkitExitFullscreen();
        } else if (doc.msExitFullscreen) {
            doc.msExitFullscreen();
        } else if (doc.webkitCancelFullScreen) {
            doc.webkitCancelFullScreen();
        }
    };

    const mute = async (type: 'audio' | 'video') => {
        if (micTrack && type === 'audio') {
            await micTrack.setEnabled(!trackState.audio);
            setTrackState((ps) => {
                return { ...ps, audio: !ps.audio };
            });
        } else if (camTrack && type === 'video') {
            await camTrack.setEnabled(!trackState.video);
            setTrackState((ps) => {
                return { ...ps, video: !ps.video };
            });
            setCameraOn(!cameraOn);
        }
    };

    const leaveChannel = async () => {
        micTrack && micTrack.setEnabled(false);
        camTrack && camTrack.setEnabled(false);
        client.removeAllListeners();
        setStart(false);
        setInCall(false);

        await logOutRtm();

        client.leave();

        navigateTo('/endConversation', { state: true });
    };

    useEffect(() => {
        document.fullscreenElement === null && setFullSize(false);
    }, [document.fullscreenElement]);

    useEffect(() => {
        if (micOnly) {
            setTrackState((ps) => {
                return { ...ps, video: false };
            });
            setCameraOn(false);
        }
    }, [micOnly]);

    useEffect(() => {
        if (connectionInfo === 'ABORTED')
            navigateTo('/endConversation', { state: true });
    }, [connectionInfo]);

    return (
        <div
            className={`c-controls d-flex -dl12 justify-content-center -mt24 ${
                fullSize ? '-fullScreen' : ''
            } ${users[0] && users[0].videoTrack ? '-callerCamOn' : ''}`}
        >
            <TwoStateBtn
                type='video'
                func={() => {
                    clearTimeout(timeoutId);
                    setTemporaryDisabled(true);
                    setMicOnly(false);
                    mute('video');
                    const currentTimeoutId = setTimeout(
                        () => setTemporaryDisabled(false),
                        1500
                    );

                    setTTimeoutId(currentTimeoutId);
                }}
                state={trackState.video}
                isDisabled={!camReady || temporaryDisabled}
            />
            <TwoStateBtn
                type='audio'
                func={() => mute('audio')}
                state={trackState.audio}
            />
            {fullSize ? (
                <OneStateBtn
                    type='maximize'
                    func={() => setVideoToNormalScreen()}
                    red={false}
                    className='d-none d-lg-flex'
                />
            ) : (
                <OneStateBtn
                    type='maximize'
                    func={() => setVideoToFullScreen()}
                    red={false}
                    className='d-none d-lg-flex'
                />
            )}
            <OneStateBtn
                type='end'
                func={() => leaveChannel()}
                sizeLg={true}
                className={isDoctor ? 'd-none' : ''}
            />
            <ChatBtn
                type='maximize'
                func={() => setOpenChat(true)}
                mobile={true}
                newMsg={isNewMessageReceived}
                setNewMsg={() => {
                    setIsNewMessageReceived(false);
                }}
                className='d-flex d-lg-none'
            />
            <LostConnectionInfo
                type={
                    isUnstableConnection
                        ? 'unstableConnection'
                        : 'lostConnection'
                }
                isReconnecting={
                    isUnstableConnection || connectionInfo === 'RECONNECTING'
                }
            />
        </div>
    );
};
