
const crypto = require('crypto')
const iDevideNum = Number(23351);
const sBaseChars = 'I4UTMCEPS12W9XY8HNQGR7B0FLD5Z';
const sDecChars = '0123456789';


const sha1 = async (text: string) =>
    crypto.createHash('sha1').update(text, 'binary').digest('hex')

export const idEncode = (
  numstring: string,
  frombase: string,
  tobase: string
): string => {
  const fromCount = frombase.length;
  const toCount = tobase.length;

  const randomHashedNumbers: number[] = Array.from(numstring, (char) =>
    frombase.indexOf(char)
  );

  let result = '';
  do {
    let divide = 0;
    let newlen = 0;
    for (let i = 0; i < randomHashedNumbers.length; i++) {
      divide = divide * fromCount + randomHashedNumbers[i];
      if (divide >= toCount) {
        randomHashedNumbers[newlen++] = Math.floor(divide / toCount);
        divide %= toCount;
      } else if (newlen > 0) {
        randomHashedNumbers[newlen++] = 0;
      }
    }
    randomHashedNumbers.length = newlen;
    result = tobase[divide] + result;
  } while (randomHashedNumbers.length > 0);

  return result;
};

export const encodeToken = async (
  token: string,
  buildNumber: string
): Promise<string> => {
  const today = new Date().toISOString().split('T')[0];
  const idEncoded = idEncode(
    `${Number(buildNumber) + iDevideNum}`,
    sDecChars,
    sBaseChars
  );

  const encodedToken = await sha1(`${buildNumber}${today}${token}`);
  return `${encodedToken}${idEncoded}`;
};
