import React, { useContext, useEffect, useRef, useState } from 'react';

import { OneStateBtn, RoundButton } from '../Buttons';
import Attachment from '../Attachment/Attachment';
import { CallerNotHere } from '../CallerNotHere';
import LostConnectionInfo from '../ReconnectingInfo/LostConnectionInfo';
import FileOversizeMessage from '../FileOversizeMessage/FileOversizeMessage';
import ChatCallDetails from '../ChatCallDetails/ChatCallDetails';
import CustomChatInput from '../CustomChatInput/CustomChatInput';

import useChat from './hooks';
import { useTranslation } from 'react-i18next';

import paperclipIcon from '../../../../assets/svg/paperclip.svg';
import paperclipGreyIcon from '../../../../assets/svg/paperclipGrey.svg';
import alertIcon from '../../../../assets/svg/alert.svg';

import { ICallDataContext } from '../../../../types/Call';
import { CallDataContext } from '../..';
import { useNavigate } from 'react-router-dom';

export default function Chat({
    mobile,
    setOpenChat,
    callData,
    setIsNewMessageReceived,
    openChat,
    chatName,
    userId,
    isChatOnlyMode,
    setIsChatOnlyMode,
    isUnstableConnection,
    setMicOnly,
}: {
    mobile: boolean;
    setOpenChat: (state: boolean) => void;
    openChat: boolean;
    callData: ICallDataContext;
    setIsNewMessageReceived: (isNewMsg: boolean) => void;
    chatName: string;
    userId: string;
    isChatOnlyMode: boolean;
    setIsChatOnlyMode: (state: boolean) => void;
    isUnstableConnection: boolean;
    setMicOnly: (state: boolean) => void;
}) {
    const { membersList, logOutRtm, connectionInfo, isDoctor }: ICallDataContext =
        useContext(CallDataContext);
    const {
        submitMessage,
        messages,
        textArea,
        setTextArea,
        downloadFile,
        uploadMediaFile,
        leaveChannel,
        scrollToBottom,
        resizeTextArea,
        fileUploadState,
        cancelUploading,
        setIsFileOversize,
        isFileOversize,
        setMessages,
        sendChannelMessage,
        textAreaRef,
        createInputMediaFile,
    } = useChat(
        isDoctor ? callData.doctor : callData.patientName,
        chatName,
        userId,
        isDoctor,
        isChatOnlyMode
    );
    const [messagesLength, setMessagesLength] = useState(0);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [firstRender, setFirstRender] = useState(true);

    const { t } = useTranslation();
    const chatMessagesBoxRef = useRef<HTMLDivElement>(null);
    const { fileName, blob, status } = fileUploadState;
    const navigate = useNavigate();
    const textAreaValue = textArea.trim().length;

    const resendMessage = (index: number, text: string) => {
        const newArray = [...messages];
        newArray.splice(index, 1);

        setMessages(newArray);
        sendChannelMessage(text);
    };
    useEffect(() => {
        if (messages.length > 0 && !openChat) setIsNewMessageReceived(true);
    }, [messages]);
    useEffect(() => {
        if (
            messages.length > messagesLength &&
            messages[messages.length - 1].user.userName !== '(Me)'
        )
            setMessagesLength(messages.length);
        scrollToBottom(chatMessagesBoxRef);
    }, [messages, messagesLength, openChat]);

    useEffect(() => {
        window.addEventListener('beforeunload', leaveChannel);
        return () => {
            window.removeEventListener('beforeunload', leaveChannel);
        };
    }, []);

    useEffect(() => {
        if (membersList && membersList.length > 1) setFirstRender(false);
    }, [membersList]);

    return (
        <>
            <div className={`c-visitPage_chat ${isChatOnlyMode ? '-chatOnlyMode' : ''}`}>
                <div className="c-chatOnlyMode_wrapper d-flex_col">
                    {isFileOversize && (
                        <FileOversizeMessage
                            closeFunction={setIsFileOversize}
                            videoMode={isChatOnlyMode}
                        />
                    )}

                    {isChatOnlyMode && (
                        <LostConnectionInfo
                            isReconnecting={
                                isUnstableConnection || connectionInfo === 'RECONNECTING'
                            }
                            type={isUnstableConnection ? 'unstableConnection' : 'lostConnection'}
                        />
                    )}

                    <div className="c-visitPage_chatTitle">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="c-visitPage_chatTitle_header d-flex justify-content-between -fullWidth">
                                <div>
                                    <h3 className="t-text -f20_27 -lSpacing-3 -wMBold -cBlack5">
                                        {t('chat.general.ChatMain')}
                                        {isDoctor ? callData.patientName : `${callData.doctor}`}
                                    </h3>
                                    <span>
                                        {t('chat.general.ChatID')}
                                        {callData.visitId}
                                    </span>
                                </div>
                                {isChatOnlyMode && (
                                    <div>
                                        <button
                                            className={`c-visitPage_chatTitle_header_endCallBtn -f14${
                                                isDoctor ? ' d-none' : ''
                                            }`}
                                            onClick={() => {
                                                logOutRtm();
                                                navigate('/endConversation');
                                            }}
                                        >
                                            {t('chat.general.EndVisit')}
                                        </button>
                                        {mobile && (
                                            <button
                                                className="c-visitPage_chatTitle_header_callDetailsBtn -f14"
                                                onClick={() => setDetailsOpen(true)}
                                            >
                                                {t('chat.general.Details')}
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>

                            <div className="c-chatControllers d-flex">
                                {!isChatOnlyMode && mobile && (
                                    <div className="c-chatControllers_closeBtn">
                                        <RoundButton type="close" func={() => setOpenChat(false)} />
                                    </div>
                                )}
                                {isChatOnlyMode && (
                                    <>
                                        <RoundButton
                                            type="phone"
                                            func={() => {
                                                setMicOnly(true);
                                                setIsChatOnlyMode(false);
                                            }}
                                        />
                                        <RoundButton
                                            extraClassName={'-camIcon'}
                                            type="camera"
                                            func={() => setIsChatOnlyMode(false)}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className="c-visitPage_chatContent d-flex_col flex-column py-5 px-3 -pt24 -pb24"
                        ref={chatMessagesBoxRef}
                    >
                        {isChatOnlyMode && membersList?.length < 2 && firstRender ? (
                            <CallerNotHere isChat={isChatOnlyMode} />
                        ) : (
                            <>
                                {messages.map((data, index) => {
                                    const checkLastSystemMsg = (i: number) => {
                                        let arr: string[] = [];
                                        messages.forEach((msg) => {
                                            arr.push(msg.user.userName);
                                        });

                                        if (arr[i + 1] !== 'System') {
                                            return '-borderBottom';
                                        }
                                        return '';
                                    };

                                    return (
                                        <div
                                            className={`row ${
                                                data.user.userName === '(Me)'
                                                    ? 'c-chat_ownMessage'
                                                    : data.user.userName === 'System'
                                                    ? `c-chat_systemMessage ${checkLastSystemMsg(
                                                          index
                                                      )}`
                                                    : data.user.userName === 'SystemError'
                                                    ? 'c-chat_systemErrorMessage'
                                                    : 'c-chat_message'
                                            }`}
                                            key={`chat${index + 1}`}
                                        >
                                            {index === 0 ||
                                            (index > 0 &&
                                                messages[index - 1].user.userName !==
                                                    data.user.userName) ||
                                            data.user.userName === 'System' ? (
                                                <span
                                                    className={`d-block c-chat_time t-text -f14_24 ${
                                                        data.user.userName === '(Me)'
                                                            ? '-own -cDarkGreen '
                                                            : '-cGrey4'
                                                    }`}
                                                >
                                                    {data.time}
                                                </span>
                                            ) : null}
                                            {data.fileId ? (
                                                <Attachment
                                                    status=""
                                                    fileName={data.message}
                                                    clickFunction={() =>
                                                        downloadFile(data.fileId, data.message)
                                                    }
                                                />
                                            ) : (
                                                <>
                                                    <p className="text-break">{` ${data.message} `}</p>
                                                    <img
                                                        className="d-none"
                                                        src={alertIcon}
                                                        alt=""
                                                    />
                                                    {data.user.isError && (
                                                        <span className="c-resendMessage t-text -f11 d-flex -mb8 -mb0md -mt8 -mt4md">
                                                            <img
                                                                className="c-resendMessage_icon"
                                                                src={alertIcon}
                                                                alt="alert icon"
                                                            />
                                                            {t('chat.general.MessageFailed')}
                                                            <button
                                                                className="c-resendMessage_btn t-text -f11"
                                                                onClick={() =>
                                                                    resendMessage(
                                                                        index,
                                                                        data.message
                                                                    )
                                                                }
                                                            >
                                                                {t('chat.general.TryAgain')}
                                                            </button>
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </div>
                    <div className="d-flex align-items-end -dl8">
                        <div className="c-visitPage_chatArea d-flex align-items-center">
                            <CustomChatInput
                                submitMessage={submitMessage}
                                setTextArea={setTextArea}
                                textArea={textArea}
                                textAreaRef={textAreaRef}
                                fileName={fileName}
                                status={status}
                                cancelFunction={cancelUploading}
                                blob={blob}
                                membersList={membersList}
                                textAreaValue={textAreaValue}
                                uploadMediaFile={uploadMediaFile}
                            />
                            <label
                                htmlFor="inputMediaFile"
                                className={`c-visitPage_chatArea_label ${
                                    membersList?.length < 2 ? '-disabled' : ''
                                }`}
                            >
                                <img
                                    src={
                                        membersList?.length < 2 ? paperclipGreyIcon : paperclipIcon
                                    }
                                    alt="paperclip"
                                />
                            </label>
                            <input
                                disabled={membersList?.length < 2}
                                type="file"
                                name="test"
                                id="inputMediaFile"
                                className="c-visitPage_chatArea_fileInput"
                                onChange={() => createInputMediaFile()}
                            />
                        </div>

                        <div>
                            <OneStateBtn
                                disabled={textAreaValue === 0 && status !== 'loaded'}
                                type="send"
                                func={() => {
                                    submitMessage();
                                }}
                                red={false}
                            />
                        </div>
                    </div>
                </div>

                {isChatOnlyMode && (detailsOpen || !mobile) && (
                    <ChatCallDetails
                        mobile={mobile}
                        setDetailsOpen={setDetailsOpen}
                        chatName={chatName}
                    />
                )}
            </div>
        </>
    );
}
