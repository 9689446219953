import React from 'react';
import { ICameraVideoTrack } from 'agora-rtc-react';
import { useTranslation } from 'react-i18next';

import cammOffBlack from '../../../../assets/svg/camOff-black.svg';
import cammOff from '../../../../assets/svg/camOff.svg';

interface IOwnCameraOff {
    camTrack?: ICameraVideoTrack | null;
    callerVideoOn: boolean;
    fullSize: boolean;
}

export function OwnCameraOff({
    camTrack,
    callerVideoOn,
    fullSize,
}: IOwnCameraOff) {
    const { t } = useTranslation();

    return (
        <div
            className={`c-info -ownCameraOff -bgGrey5 d-flex align-items-center -dl8 ${
                callerVideoOn ? '-callerOn' : ''
            } ${fullSize ? '-fullScreenMode' : ''}`}
        >
            <img
                src={callerVideoOn ? cammOff : cammOffBlack}
                alt='turned off camera off'
                className='c-info_icon'
            />
            <span className='t-text -f11 -wLBold -lSpacing3'>
                {t('video.general.OwnCameraDisabled')}
            </span>
        </div>
    );
}
