import React from 'react';

interface Props {
    icon: {
        src: string;
        alt: string;
    };
    title: string;
    description: string;
    thankYouText?: string;
}

export function VisitInfo(props: Props) {
    return (
        <div className="visit_info">
            <div className="visit_info_container container">
                <div className="visit_info_icon">
                    <img src={props.icon.src} alt={props.icon.alt} />
                </div>
                <div className="visit_info_title t-text -tCenter -f20 -mt32">{props.title}</div>
                <div className="visit_info_description t-text -tCenter -f14 -mt16">
                    {props.description}
                </div>
                {props.thankYouText && (
                    <div className="visit_info_thank_you_text t-text -tCenter -f14 -mt12">
                        {props.thankYouText}
                    </div>
                )}
            </div>
        </div>
    );
}
