import React from 'react';
import { t } from 'i18next';

import VisitNotFoundIcon from '../../assets/svg/visit-not-found.svg';
import { VisitInfo } from '../../components/VisitInfo/VisitInfo';
import { VisitInfoPageContainer } from '../../components/VisitInfoPageContainer/VisitInfoPageContainer';

export function NotFoundPage() {
    return (
        <VisitInfoPageContainer>
            <VisitInfo
                title={t('notFound.Title')}
                icon={{
                    src: VisitNotFoundIcon,
                    alt: t('notFound.ImageAlt'),
                }}
                description={t('notFound.Description')}
            />
        </VisitInfoPageContainer>
    );
}
