import React, { ReactElement } from 'react';

import ReconnectingCircle from '../../../../assets/svg/reconnectingCircle.svg';
import ConnectionStatusRed from '../../../../assets/svg/connectionStatusRed.svg';
import ConnectionStatusOrange from '../../../../assets/svg/connectionStatusOrange.svg';

import { useTranslation } from 'react-i18next';

interface ILostConnectionInfo {
    isReconnecting: boolean;
    type: 'lostConnection' | 'unstableConnection';
}

const LostConnectionInfo = ({
    isReconnecting,
    type,
}: ILostConnectionInfo): ReactElement => {
    const { t } = useTranslation();

    return (
        <div
            className={`c-lostConnectingInfo d-flex ${
                isReconnecting ? '-active' : ''
            }`}
        >
            <img
                className='c-lostConnectingInfo_connectionStatusIcon'
                src={
                    type === 'unstableConnection'
                        ? ConnectionStatusOrange
                        : ConnectionStatusRed
                }
                alt='reconnecting circle'
            />
            <p className='c-lostConnectingInfo_text t-text -f14_21'>
                {type === 'unstableConnection'
                    ? t('networkStatus.UnstableConnection')
                    : t('networkStatus.LostConnection')}
            </p>
            {!(type === 'unstableConnection') && (
                <img
                    className='c-lostConnectingInfo_reconnectingIcon'
                    src={ReconnectingCircle}
                    alt='connection status'
                />
            )}
        </div>
    );
};

export default LostConnectionInfo;
